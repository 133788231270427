/** @jsx jsx */
//import React from 'react'
import {Box,jsx,Heading,Grid,Text,Link as ThLink} from 'theme-ui'
//import {useSpring,animated,config} from 'react-spring'
import Layout from '../../components/layout'
import MySEO from '../../components/seo'
import {Link} from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import {graphql} from 'gatsby'


// const Marko = () => {
//   return(
//   <Box sx={{height:400,marginTop:'20px'}}>
// <iframe width="100%"  height="315" src="https://www.youtube.com/embed/mjKRgnnpVlo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
// </Box>
//   )
// }



export default function FuenfterSalon({data}){
// const [yout,setYout] =React.useState(false)
return(
  <Layout>
      <MySEO
     title="Der sechste Salon von K.0.M in St. Goar"
     
    />
    <Box sx={{maxWidth:1100,paddingBottom:40}}>
    <Heading sx={{
                     fontWeight:500,
                     fontSize:[2,3],
                     mt:4
                 }}> Salon N° 6 
          </Heading>
         <Text>am Freitag, 23. Juni 2023, Beginn 19:00 Uhr<br/>
         Ort: Foyer in der Rheinfelshalle, Sankt Goar,<br/>Heerstraße 139, 56329 Sankt Goar
          </Text>
         
{/* <Grid columns={[1,1,2]} gap={3}>
   
  
  </Grid> */}


  <Grid mt="20px" columns={[1,1,2]} gap={5} >
  <Box>
    <Heading sx={{variant:'heading.titel'}}>Trotz alledem und alledem – <br/>Ferdinand Freiligrath </Heading>
  <Text >
  <span sx={{fontWeight:700}}>Schauspiel mit Musik</span> mit Christoph Maasch & Sven Marko Schmidt (Schauspiel), Katrin Zurborg (Gitarre & musikalische Leitung), Walla Heldermann (Inszenierung & Produktionsleitung) 
   </Text>
 
     <Box mb="20px" py="2">
  <a href="/pdf/Flyer_Salon6.pdf">Download Flyer</a> 
  </Box>
    
      <Heading sx={{variant:"heading.titel2"}}>Ferdinand Freiligrath </Heading>
    <Text>
    
Er war Demokrat, geistiger Unterstützer und Idol der 
Revolution von 1848. Er wurde verfolgt als Aufrührer, doch zuvörderst blieb er immer ein großer Dichter der deutschen Sprache. Auch am Rhein hat er seine Spuren hinterlassen. In Sankt Goar war Ferdinand Freiligrath von 1842 bis 1844 Mittelpunkt eines Kreises von Dichtern, Denkern und liberalen Bürgern.
</Text>
<Text>
Das Ensemble von taw – theater am werk – erweckt diese Zeit des Umbruchs zu neuem Leben mit dem Theaterprojekt: „Trotz alledem und alledem – Ferdinand Freiligrath“
</Text>
     <Text sx={{fontWeight:600}}>Der Eintritt ist frei. Um Spenden wird gebeten.</Text>
   <Text>
   <span sx={{fontWeight:700}}>Schauspiel:</span> Christoph Maasch und Sven Marko Schmidt <br/>
   <span sx={{fontWeight:700}}>Jazz Gitarre:</span> Katrin Zurborg<br/>
<span sx={{fontWeight:700}}>Inszenierung:</span> Walla Heldermann<br/>
   </Text>
  
  <a href="http://www.theater-am-werk.de/" rel="noopener noreferrer" target='_blank'><Text>&rarr; Eine Produktion von taw – theater am werk koblenz</Text></a>
  <Heading sx={{variant:"heading.titel2"}}>Regisseurin & Akteure </Heading>
  <Text>
  <span sx={{fontWeight:700}}>Walla Heldermann</span> studierte Theater­pädagogik in Köln und Heidelberg, absolvierte die Schauspiel­ausbildung in Bonn, eine Regieausbildung in Moskau, Berlin und Essen sowie 
diverse Studien in Tanz, Sprache und Gesang. 2001 gründete Walla Heldermann das taw – theater am werk koblenz e.V. 
  </Text>
  <Text>
  <span sx={{fontWeight:700}}> Katrin Zurborg</span>  arbeitet als Gitarristin, Sängerin, Komponistin und Arrangeurin in verschiedenen Formationen. Sie ist Mitbegründerin des Modern-Jazz-Trios Feinherb, in Frankfurt leitet sie die Kulturwerkstatt Waggong und ist als Kulturreferentin tätig. Sie ist festes Mitglied im ‚theater am werk‘-Ensemble und zuständig für die musikalische Leitung. 
  </Text>
  <Text>
  <span sx={{fontWeight:700}}>Christoph Maasch  </span>
ist seit 1999 als freier Schauspieler, Regisseur und Sprecher tätig. Seitdem hat er in zahlreichen Theaterproduktionen, Live-Hörspielen und Literatur-Musik-Projekten mitgewirkt sowie als Sprecher für Funk, Fernsehen, Hörspiele, Filme und Werbung gearbeitet. Neben seinen Auftritten beim ‚theater am werk‘ ist er regelmäßig bei den Landungsbrücken, 
der Dramatischen Bühne und dem Stalburg Theater in Frankfurt zu sehen.</Text>
<Text><span sx={{fontWeight:700}}>Sven Marko Schmidt </span> ist seit 1998 in Frankfurt als freier Schauspieler und Sprecher tätig. Neben der Mitgründung des Papageno-Musiktheaters Frankfurt 1998 und langjähriger Ensemble­mitgliedschaft ebendort, ist er seither auch in vielen freien Theaterproduktionen, im Fernsehen und als ynchronsprecher zu sehen und zu hören. Darüber­hinaus tourt er als Clowndoktor durch die Kinderkliniken des Rhein-Main-Gebietes.</Text>

<Heading sx={{variant:"heading.titel2"}}>Das Glasfoyer in der Rheinfelshalle </Heading>
<Text>Für den sechsten Salon freuen wir uns, das architektonisch wunderbar offene, vielseitig nutzbare Glasfoyer der Rheinfelshalle – bereits Klassik­freunden durch Konzerte der Internationalen Musik­akademie Sankt Goar bekannt – bespielen zu dürfen. </Text>

<Heading sx={{variant:"heading.titel2"}}>Die Initiative freili. </Heading>
<Text>Thematisch passend sei auch auf unsere neue Initiative – das 
   <span sx={{fontWeight:800}}> freili.</span> – hingewiesen, ein (ideeller) Raum für Demokratie und Freundschaft. Noch steckt unsere Idee dieser Freiligrath-Bürgerstiftung in den Kinderschuhen, wir freuen uns aber über jegliche Unterstützung!</Text>
<a rel="noopener noreferre" target="_blank" href=" https://freili.netlify.app">Initiative Freiligrath</a>



      
        </Box>
  <Box>     
<Grid columns={[2]} gap={3}>
 
  
  <GatsbyImage image={data.zwei.childImageSharp.gatsbyImageData} alt="Der Gastgeber"/> 
  <Text sx={{variant:"text.photo"}}>links: Ernst Zinna und Heinrich Glasewaldt auf der Barrikade, Lithographie von Theodor Hosemann zur Märzrevolution 1848 in Berlin. rechts: Ferdinand Freiligrath lebte von 1842 bis 1844 in Sankt Goar und wandelte sich währenddessen vom romantischen Dichter zum „Trompeter der Revolution“. (Fotos: wikipedia, gemeinfrei)</Text>
<GatsbyImage image={data.drei.childImageSharp.gatsbyImageData} alt="Marko Mebus"/> 
<Text sx={{variant:"text.photo"}}  mt="6px">Sven Marko Schmidt (links) und Christoph Maasch (rechts) sowie unten Katrin Zurborg während der Probe zu „Trotz alledem und alledem“. (Fotos: taw)</Text>


 <GatsbyImage image={data.vier.childImageSharp.gatsbyImageData} alt=""/> 

 <Text sx={{variant:"text.photo"}}> </Text>
 <GatsbyImage image={data.fuenf.childImageSharp.gatsbyImageData} alt="Rheinfelshalle St. Goar"/> 

<Text sx={{variant:"text.photo"}}> Das gläserne Foyer der Rheinfelshalle in Sankt Goar eignet sich hervorragend für kleinere Bühnenformate wie Kammerkonzerte oder szenisches Schauspiel. (Foto: K.O.M.)</Text>
 


 


</Grid>
</Box>
</Grid>



<Box mt="40px">
<Text>
Wir freuen uns über Ihre A N M E L D U N G unter:<br/>
kontakt@kulturnetz-oberes-mittelrheintal.org<br/>
Das Programm startet um 19:00 Uhr. <br/>Die letzte Fähre ab Sankt Goar fährt um 22:30 Uhr.
</Text>
</Box>
<p sx={{fontSize:3,py:3}}>Dank an die Förderer</p>
<p sx={{fontWeight:600}}>

Dieser K.O.M.-Salon N°6 wird gefördert durch die „Ehrenamtlichen Bürgerprojekte“ der LAG Welterbe Oberes Mittelrheintal.
</p>
<GatsbyImage image={data.logo.childImageSharp.gatsbyImageData} alt="Förderer"/> <br/>
<Link to="/salons"><ThLink>Salons</ThLink></Link>
</Box>
    </Layout>
)
}

export const WEGquery = graphql`
  query {
    zwei:file(relativePath: { eq: "salons/salon6/bild-2.jpeg"}) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        gatsbyImageData(
         
         
          layout: CONSTRAINED
          placeholder: BLURRED
        
        )
      }
    }
  drei:file(relativePath: { eq: "salons/salon6/bild-3.jpeg"}) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          gatsbyImageData(
          
            layout: CONSTRAINED
            placeholder: BLURRED
          
          )
        }
      }
      vier:file(relativePath: { eq: "salons/salon6/bild-4.jpeg"}) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          gatsbyImageData(
           
            layout: CONSTRAINED
            placeholder: BLURRED
          
          )
        }
      }
    fuenf:file(relativePath: { eq: "salons/salon6/bild-5.jpeg"}) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          gatsbyImageData(
            
            layout: CONSTRAINED
            placeholder: BLURRED
          
          )
        }
      }
      fels:file(relativePath: { eq: "salons/salon6/bild-1.jpeg"}) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          gatsbyImageData(
            
            layout: CONSTRAINED
            placeholder: BLURRED
          
          )
        }
      }
      logo:file(relativePath: { eq: "salons/salon5/logos.jpg"}) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          gatsbyImageData(
           width: 460
            layout: CONSTRAINED
            placeholder: BLURRED
          
          )
        }
      }
      
    
}`
